.landing {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease-in-out;
}

.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: scale(1);
  transition: transform 1s ease-out;
  will-change: transform;
  z-index: 0;
}

.backgroundContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  scroll-snap-type: y mandatory; /* 스크롤 스냅 설정 */
}

.contentWrapper::-webkit-scrollbar {
  display: none;
}

.content {
  position: relative;
  width: 100%;
  height: 400vh; /* 전체 높이 */
  z-index: 1;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  scroll-snap-align: start;
}

.ttubeotLogoContainer {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.ttubeotLogo {
  width: 30vw;
}

.buttonContainer {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 1.5vw;
  align-items: center;
  z-index: 10;
}

button {
  border-radius: 5px;
  width: 10vw;
  height: 5vh;
  background: linear-gradient(#f9fbdd, #eccfae);
  padding: 1vh 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: bold;
  z-index: 5;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.uccModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  height: 70vh;
  border-radius: 40px;
  border: 5px solid rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 12;
  gap: 12px;
}

.uccTitle {
  font-size: 24px;
  font-weight: bold;
}

.ucc {
  background-color: #727272;
  width: 90%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  /* border: none; */
  width: 30px;
  height: 30px;
  font-size: 24px;
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.animalImage {
  position: absolute;
  bottom: 10%;
  width: 150px;
  height: auto;
  z-index: 10;
}

.slideInLeft {
  animation: slideInLeft 0.5s ease-out;
  left: 25%; /* 왼쪽에서 나오는 이미지를 중앙 쪽으로 이동 */
}

.slideInRight {
  animation: slideInRight 0.5s ease-out;
  right: 25%; /* 오른쪽에서 나오는 이미지를 중앙 쪽으로 이동 */
}
.scrollHint {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.wheelIcon {
  width: 30px;
  height: 30px;
  border: 3px solid #333333; /* 더 진한 색상 */
  border-radius: 50%;
}

.arrow {
  width: 15px;
  height: 15px;
  border-right: 3px solid #333; /* 더 진한 화살표 색상 */
  border-bottom: 3px solid #333;
}

.arrowUp {
  transform: rotate(-135deg); /* 위쪽 화살표 방향 */
  animation: arrowMoveUp 1.5s infinite;
}

.arrowDown {
  transform: rotate(45deg); /* 아래쪽 화살표 방향 */
  animation: arrowMoveDown 1.5s infinite;
}

@keyframes arrowMoveUp {
  0%,
  100% {
    transform: translateY(0) rotate(-135deg);
  }
  50% {
    transform: translateY(-10px) rotate(-135deg);
  }
}

@keyframes arrowMoveDown {
  0%,
  100% {
    transform: translateY(0) rotate(45deg);
  }
  50% {
    transform: translateY(10px) rotate(45deg);
  }
}
